import { FloatingWhatsApp } from 'react-floating-whatsapp'
import './App.css';
import logo from "./bjou.png"
import pdf from "./pdf.pdf"
import video from "./mainvideo.mp4"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import mobilevideo from "./mobilevd.gif"
import React, { useState, useRef, useEffect, useCallback } from 'react';
function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contactModal, setContactModal] = useState(false)
  const [isRegisterFormVisible, setIsRegisterFormVisible] = useState(false);
  const [showMessage, setShowMessage] = useState(false)
  const [showContactMessage, setShowContactMessage] = useState(false)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [regulatoryBody, setRegulatoryBody] = useState('');
  const [regulatoryReferenceNumber, setRegulatoryReferenceNumber] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [countryError, setCountryError] = useState(false)
  const [btnclick, setbtnclick] = useState(false)
  const [ForgetEmailcheck, setForgetEmailcheck] = useState(false);
  const [logedin,setlogedin] = useState(false)
  const [ForgetEmail, setForgetEmail] = useState('')
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    confirmEmail: false,
    telephoneNumber: false,
    company: false,
    role: false,
    selectedCountry: false,

  });
  const [forgetPassword, setForgetPassword] = useState(false)

  const handleRegister = () => {
    const errors = {};
    let hasError = false;

    // Check required fields
    if (!firstName || !firstName.trim()) {
      errors.firstName = true;
      hasError = true;
    }
    if (!lastName || !lastName.trim()) {
      errors.lastName = true;
      hasError = true;
    }
    if (!email || !email.trim()) {
      errors.email = true;
      hasError = true;
    }
    if (!confirmEmail || !confirmEmail.trim() || confirmEmail.trim() !== email.trim()) {
      errors.confirmEmail = true;
      hasError = true;
    }
    if (!telephoneNumber || !telephoneNumber.trim() || telephoneNumber.length < 3) {
      errors.telephoneNumber = true;
      hasError = true;
    }
    if (!company || !company.trim()) {
      errors.company = true;
      hasError = true;
    }
    if (!role || !role.trim()) {
      errors.role = true;
      hasError = true;
    }

    console.log("country is this", selectedCountry)
    if (selectedCountry.length === 0 && selectedCountry !== 'Country') {
      errors.selectedCountry = true;
      hasError = true;
    }

    if (hasError) {
      setFormErrors(errors);
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);


    }
  }
  const handleCountryChange = event => {


    setSelectedCountry(event.target.value);
  };
  useEffect(() => {
    const country = selectedCountry
    console.log("selected country is", country)
  }, [selectedCountry])
  const videoRef = useRef(null);
  useEffect(() => {
    fetch(
      'https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code'
    )
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch countries');
        }
        return response.json();
      })
      .then(data => {
        const modifiedCountries = data.countries.map(country => ({
          value: country.value,
          label: country.label.split(' ').slice(1).join(' ')
        }));
        setCountries(modifiedCountries);

      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });

  }, []);


  // const handlePlayPause = () => {
  //   if (isPlaying) {
  //     videoRef.current.pause();
  //   } else {
  //     videoRef.current.play();
  //   }
  //   setIsPlaying(!isPlaying);
  // };
  const handlePlayPause = () => {
    const video = document.querySelector('.bjou-video'); // Select video element by class

    if (isPlaying) {
      video.pause();
    } else {
      video.play().catch(error => {

        console.error('Failed to play:', error);
      });
    }

    setIsPlaying(!isPlaying);
  };
  useEffect(() => {
    const video = document.querySelector('.bjou-video');
    video.setAttribute('muted', '');
    video.setAttribute('playsinline', '');
    video.muted = true;
    var playingvideo = video.play();
    if (playingvideo !== undefined) {
      playingvideo.then(function () {

      }).catch(function (error) {
        // Likely low power mode on iOS, show controls
        video.setAttribute('controls', '');
      });
    }
  }, [])
  const showModal = () => {
    setIsModalVisible(!isModalVisible);
    setIsRegisterFormVisible(false);
    setShowMessage(false);

  };
  const showContactModal = () => {
    setContactModal(!isModalVisible);

    setShowContactMessage(false);
  };
  const showRegisterForm = () => {
    setIsRegisterFormVisible(!isRegisterFormVisible);

  };
  const handleLoginOrSubmit = () => {
    setlogedin(true);
  };
  const handleContactMessage = () => {
    setShowContactMessage(true);

  }
  const openForget = () => {
    setForgetPassword(!forgetPassword);
    setShowMessage(false);
    setIsModalVisible(false);
  }
  const handleForgotSubmit = () => {
    if (ForgetEmail !== '')
      setShowMessage(true);
    else
      setForgetEmailcheck(true)
  }
  const showLogin = () => {
    setForgetPassword(!forgetPassword);
    setShowMessage(false);
    setIsModalVisible(true);
  }
  const setbtnclickValue = () => {
    const allErrors = Object.values(formErrors).every(error => error === false);
    if (allErrors) {
      setbtnclick(false)
    }
    else {
      setbtnclick(true)
      setShowMessage(true)
    }
  }
  useEffect(() => {
    console.log("btn value are", btnclick)
  }, [btnclick])
  return (
    <div className="App">
      <div className="header">
        <span className="logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="250" height="116.769" viewBox="0 0 345 116.769">
            <g id="BJOU_CAPITAL_LOGO" data-name="BJOU CAPITAL LOGO" transform="translate(-477.463 -204.845)">
              <path id="Path_1" data-name="Path 1" d="M683,257.216a8.054,8.054,0,0,0,3.366-6.574,6.682,6.682,0,0,0-4.71-6.688,5.682,5.682,0,0,0,3.031-5.269,7.146,7.146,0,0,0-3.886-6.2c-2.32-1.16-4.859-1.047-7.363-1.047h-5v27.611h6.9C678,258.972,680.806,258.823,683,257.216Zm-9.447-20.811h.933a8.407,8.407,0,0,1,3.36.262,2.626,2.626,0,0,1,1.719,2.429,2.821,2.821,0,0,1-1.567,2.54,8.987,8.987,0,0,1-3.473.411h-.972Zm0,10.612h2.24a7.568,7.568,0,0,1,3.622.558,3.224,3.224,0,0,1,.487,5.529,7.065,7.065,0,0,1-4.226.973h-2.123Z" transform="translate(-17.871 -2.466)" />
              <path id="Path_2" data-name="Path 2" d="M709.071,256.546c1.272-1.87,1.344-3.512,1.344-5.642V231.438h-5.12v19.8c0,1.794-.409,3.362-2.539,3.362a2.27,2.27,0,0,1-2.35-1.9h-5.122c.074,4.22,3.661,7.023,7.7,7.023A7.242,7.242,0,0,0,709.071,256.546Z" transform="translate(-20.383 -2.467)" />
              <path id="Path_3" data-name="Path 3" d="M750.118,245.318a14.4,14.4,0,1,0-14.384,14.348A14.39,14.39,0,0,0,750.118,245.318ZM735.7,254.7a9.4,9.4,0,0,1-9.267-9.34,9.226,9.226,0,0,1,9.267-9.417l-.035-.114A9.436,9.436,0,0,1,735.7,254.7Z" transform="translate(-22.819 -2.413)" />
              <path id="Path_4" data-name="Path 4" d="M781.523,231.438h-5.112v17.226a7.5,7.5,0,0,1-.754,4.147,4.194,4.194,0,0,1-3.473,1.941,4.111,4.111,0,0,1-3.659-2.353,7.886,7.886,0,0,1-.527-3.735V231.438h-5.116v17.226c0,2.875.227,5.416,2.24,7.694a9.185,9.185,0,0,0,14.386-.224c1.8-2.169,2.016-4.745,2.016-7.47Z" transform="translate(-26.709 -2.467)" />
              <path id="Path_5" data-name="Path 5" d="M681.067,285.324a9.623,9.623,0,0,1,7.251,3.433h5.829a14.2,14.2,0,0,0-13.115-8.406,14.406,14.406,0,0,0,.147,28.81,14.024,14.024,0,0,0,12.814-8.183h-5.935a9.123,9.123,0,0,1-16.073-6.129A9.359,9.359,0,0,1,681.067,285.324Z" transform="translate(-17.724 -7.003)" />
              <path id="Path_6" data-name="Path 6" d="M713.318,280.929l-11.505,27.612h5.642l3.057-7.137h9.794l2.985,7.137h5.645l-11.615-27.612Zm-.935,15.508,2.949-8.37,2.96,8.37Z" transform="translate(-20.994 -7.057)" />
              <path id="Path_7" data-name="Path 7" d="M754.19,282.386c-2.323-1.572-4.829-1.457-7.48-1.457h-6.5V308.54h5.116V298.076h1.01c2.723,0,5.942.151,8.219-1.68a8.887,8.887,0,0,0,3.284-7.06A8.42,8.42,0,0,0,754.19,282.386Zm-3.969,10.423a14.1,14.1,0,0,1-3.958.3h-.934V285.9h.934a16.437,16.437,0,0,1,3.815.224,3.376,3.376,0,0,1,2.65,3.29A3.532,3.532,0,0,1,750.221,292.809Z" transform="translate(-24.587 -7.057)" />
              <rect id="Rectangle_1" data-name="Rectangle 1" width="5.118" height="27.611" transform="translate(741.413 273.872)" />
              <path id="Path_8" data-name="Path 8" d="M783.066,285.9h5.081V308.54h5.122V285.9h5.153v-4.967H783.066Z" transform="translate(-28.598 -7.057)" />
              <path id="Path_9" data-name="Path 9" d="M816.226,280.929l-11.509,27.612h5.645l3.063-7.137h9.786l2.987,7.137h5.648l-11.62-27.612Zm-.934,15.508,2.948-8.37,2.959,8.37Z" transform="translate(-30.624 -7.057)" />
              <path id="Path_10" data-name="Path 10" d="M848.226,303.57V280.929h-5.115V308.54h13.568v-4.97Z" transform="translate(-34.216 -7.057)" />
              <path id="Path_11" data-name="Path 11" d="M555.9,231.382c-10.776,0-17.386,6.811-17.386,18v37.206c0,11.186,6.611,17.994,17.386,17.994s17.383-6.808,17.383-17.994V249.377C573.284,238.193,566.671,231.382,555.9,231.382Zm6.6,55.1c0,5.285-2.339,8.132-6.6,8.132s-6.61-2.846-6.61-8.132V249.477c0-5.284,2.338-8.131,6.61-8.131s6.6,2.847,6.6,8.131Z" transform="translate(-5.713 -2.439)" />
              <path id="Path_12" data-name="Path 12" d="M604.871,286.28c0,5.186-2.339,7.113-6.1,7.113s-6.1-1.927-6.1-7.113v-54.9H582.3v55.2c0,11.186,6.4,16.979,16.57,16.979,10.372,0,16.772-5.793,16.772-16.979v-55.2H604.871Z" transform="translate(-9.81 -2.44)" />
              <path id="Path_13" data-name="Path 13" d="M520.671,306.171c0,4.363-1.437,7.264-5.866,7.591v10.254c11.691,0,16.648-6.63,16.648-17.535v-75.1H520.671Z" transform="translate(-3.494 -2.402)" />
              <path id="Path_14" data-name="Path 14" d="M512.233,246.894c0-11.184-6.611-18-17.383-18a20.654,20.654,0,0,0-6.611,1.028V204.845H477.463V284.1c0,11.186,6.611,17.994,17.387,17.994s17.383-6.808,17.383-17.994ZM501.453,284c0,5.285-2.339,8.132-6.6,8.132s-6.611-2.846-6.611-8.132V246.994c0-5.284,2.339-8.132,6.611-8.132s6.6,2.847,6.6,8.132Z" transform="translate(0 0)" />
              <rect id="Rectangle_2" data-name="Rectangle 2" width="1.36" height="72.323" transform="translate(626.858 228.797)" />
              <path id="Path_15" data-name="Path 15" d="M526.6,212.473a6.051,6.051,0,1,0,6.052,6.05A6.051,6.051,0,0,0,526.6,212.473Z" transform="translate(-4.032 -0.714)" />
            </g>
          </svg>


        </span>
        <span className="cont">
          BJOU Capital is focused on investing in high-quality, small and mid-market businesses to ensure they not only grow and thrive, but benefit all stakeholders.        </span>
        <span className="cont-2">
          <h2>BJOU CAPITAL </h2>
          <p>167 Great Portland Street
            London W1W 5PF</p>
        </span>
        <span className="buttons">
          <span className="portal" onClick={showModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8.5" height="11" viewBox="0 0 8.5 11">
              <path id="PADLOCK_ICON" data-name="PADLOCK ICON" d="M1672.659,2762a.932.932,0,0,1-.945-.916v-5.729a.233.233,0,0,1,.236-.229h.708v-.917a3.307,3.307,0,0,1,6.611,0v.917h.708a.232.232,0,0,1,.236.229v5.729a.932.932,0,0,1-.945.916Zm2.361-4.125a.9.9,0,0,0,.386.737l-.149,1.3a.226.226,0,0,0,.059.178.241.241,0,0,0,.176.076h.944a.24.24,0,0,0,.176-.076.226.226,0,0,0,.059-.178l-.149-1.3a.9.9,0,0,0,.386-.737.945.945,0,0,0-1.889,0Zm-.945-3.666v.917h3.778v-.917a1.89,1.89,0,0,0-3.778,0Z" transform="translate(-1671.715 -2751)" fill="#fff" />
            </svg>
            Investor Portal
          </span>
          <span className="portal2" onClick={showContactModal}>
            Contact Us
          </span>
        </span>
      </div>
      <span className="cont-tab">
        BJOU Capital is focused on investing in high-quality, small and mid-market businesses to ensure they not only grow and thrive, but benefit all stakeholders.      </span>
      <div className="video-container">
        <video
          className="bjou-video"

          muted
          playsinline
          loop
        >
          <source src={video} type="video/mp4" ></source>
        </video>
        <img src={mobilevideo} alt="gif" />
        {/* <button className="play-icon" onClick={handlePlayPause}>
          {isPlaying ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="122.001" height="122.001" viewBox="0 0 122.001 122.001">
              <g id="Group_2" data-name="Group 2" transform="translate(-241.585 -1675.002)">
                <path id="Path_16" data-name="Path 16" d="M351.386,1675h-97.6a12.213,12.213,0,0,0-12.2,12.2v97.6a12.212,12.212,0,0,0,12.2,12.2h97.6a12.212,12.212,0,0,0,12.2-12.2v-97.6a12.213,12.213,0,0,0-12.2-12.2M260.8,1733.563H246.465v-25.62H260.8Zm-14.335,4.88H260.8v25.62H246.465Zm19.215-58.561h73.811v112.241H265.68Zm78.691,28.06h14.335v25.62H344.371Zm0,30.5h14.335v25.62H344.371Zm14.335-51.241v15.86H344.371v-23.18h7.015a7.329,7.329,0,0,1,7.32,7.32m-104.921-7.32H260.8v23.18H246.465V1687.2a7.329,7.329,0,0,1,7.32-7.32m-7.32,104.921v-15.86H260.8v23.18h-7.015a7.33,7.33,0,0,1-7.32-7.32m104.921,7.32h-7.015v-23.18h14.335v15.86a7.33,7.33,0,0,1-7.32,7.32" transform="translate(0)" fill="#fff" />
                <rect id="Pause_Left" x="280" y="1709.305" width="12" height="34" fill="#fff" transform="translate(25.921 24.584)" />
                <rect id="Pause_Right" x="304" y="1709.305" width="12" height="34" fill="#fff" transform="translate(25.921 24.584)" />
              </g>
            </svg>

          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="122.001" height="122.001" viewBox="0 0 122.001 122.001">
              <g id="Group_1" data-name="Group 1" transform="translate(-241.585 -1675.002)">
                <path id="Path_16" data-name="Path 16" d="M351.386,1675h-97.6a12.213,12.213,0,0,0-12.2,12.2v97.6a12.212,12.212,0,0,0,12.2,12.2h97.6a12.212,12.212,0,0,0,12.2-12.2v-97.6a12.213,12.213,0,0,0-12.2-12.2M260.8,1733.563H246.465v-25.62H260.8Zm-14.335,4.88H260.8v25.62H246.465Zm19.215-58.561h73.811v112.241H265.68Zm78.691,28.06h14.335v25.62H344.371Zm0,30.5h14.335v25.62H344.371Zm14.335-51.241v15.86H344.371v-23.18h7.015a7.329,7.329,0,0,1,7.32,7.32m-104.921-7.32H260.8v23.18H246.465V1687.2a7.329,7.329,0,0,1,7.32-7.32m-7.32,104.921v-15.86H260.8v23.18h-7.015a7.33,7.33,0,0,1-7.32-7.32m104.921,7.32h-7.015v-23.18h14.335v15.86a7.33,7.33,0,0,1-7.32,7.32" transform="translate(0)" fill="#fff" />
                <path id="Path_17" data-name="Path 17" d="M292.525,1709.305l-29.28-16.9a2.44,2.44,0,0,0-3.66,2.111v33.806a2.44,2.44,0,0,0,3.66,2.113l29.28-16.9a2.438,2.438,0,0,0,0-4.224m-28.06,14.789V1698.74l21.96,12.678Z" transform="translate(25.921 24.584)" fill="#fff" />
              </g>
            </svg>

          )}
        </button> */}
        <p className='desktop-copy-container'>© 2024 bjou capital. all rights reserved.</p>
      </div>
      <div className={`login-overlay ${isModalVisible ? 'show' : ''} ${isRegisterFormVisible && !showMessage ? 'register-layout' : ''}`}>
        <span className={`close ${isRegisterFormVisible ? "register-close":""}`}  onClick={showModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 23.08 23.094">
            <g id="CLOSE_X" data-name="CLOSE X" transform="translate(-1828.853 -1248.44)">
              <line id="Line_1" data-name="Line 1" x1="0.574" y2="30.538" transform="translate(1850.987 1248.98) rotate(45)" fill="none" stroke="#000" stroke-width="1.5" />
              <line id="Line_2" data-name="Line 2" x1="0.574" y1="30.538" transform="translate(1851.393 1270.588) rotate(135)" fill="none" stroke="#000" stroke-width="1.5" />
            </g>
          </svg>

        </span>
        <div className={`${isRegisterFormVisible ? 'registerpadding' : ''} logregister-form modal-content ${showMessage ? "msgoverlay" : ''}`}>
          {
            showMessage ? (
              <div className='message-container'>
                <h3 className='login-heading'>THANK YOU</h3>
                <p className='message'>We appreciate you reaching out, and if your application is successful we will contact you within 3-5 business days</p>
                <p className='closewindow' onClick={showModal}>CLOSE WINDOW</p>
              </div>
            ) : (
              <>

                {
                  isRegisterFormVisible === false ?
                    (<>
                      <h3 className='login-heading'>INVESTOR PORTAL</h3>
                      <span className='login-group form-group'>
                        <input type="email" placeholder="Email *" className='input' />
                        <input type="password" placeholder="Password *" className='input' />
                        {logedin && <span className="error-message login-error">Email or Password is not valid</span>}
                        <span className='action-buttons'>
                          <button className="login-button" onClick={handleLoginOrSubmit}>Login</button>
                          <span className="forgot-password" onClick={openForget}>Forgotten Password?</span></span>
                        <div className="register-info">
                          Not a registered investor? Click <span className="register-link" onClick={showRegisterForm}> here</span> to apply.
                        </div>
                        <div className='info-company'>


                          <p className='securelogin'><svg xmlns="http://www.w3.org/2000/svg" width="8.5" height="11" viewBox="0 0 8.5 11">
                            <path id="Union_1" data-name="Union 1" d="M1672.659,2762a.932.932,0,0,1-.945-.916v-5.729a.233.233,0,0,1,.236-.229h.708v-.917a3.307,3.307,0,0,1,6.611,0v.917h.708a.232.232,0,0,1,.236.229v5.729a.932.932,0,0,1-.945.916Zm2.361-4.125a.9.9,0,0,0,.386.737l-.149,1.3a.226.226,0,0,0,.059.178.241.241,0,0,0,.176.076h.944a.24.24,0,0,0,.176-.076.226.226,0,0,0,.059-.178l-.149-1.3a.9.9,0,0,0,.386-.737.945.945,0,0,0-1.889,0Zm-.945-3.666v.917h3.778v-.917a1.89,1.89,0,0,0-3.778,0Z" transform="translate(-1671.715 -2751)" />
                          </svg>
                            THIS IS A SECURE LOGIN WEBSITE</p>
                        </div>
                        <div className='trademark'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="48" viewBox="0 0 50 48">
                            <g id="CYBERSECURITY_LOGO" data-name="CYBERSECURITY LOGO" transform="translate(0.002 -0.03)">
                              <g id="Group_10" data-name="Group 10" transform="translate(-0.002)">
                                <path id="path268" d="M22.556,25.244H20.364v8.688h2.192Z" transform="translate(-6.224 -8.057)" />
                                <g id="use360" transform="translate(27.383 17.034)">
                                  <path id="path284" d="M46.061,27.666a3.809,3.809,0,0,0-1.842-.464,2.32,2.32,0,1,0,0,4.636,3.85,3.85,0,0,0,1.842-.461V33.71a6.933,6.933,0,0,1-2,.31,4.5,4.5,0,1,1,0-9,6.634,6.634,0,0,1,2,.312Z" transform="translate(-39.435 -25.023)" />
                                </g>
                                <path id="path284-2" data-name="path284" d="M13.107,27.666a3.8,3.8,0,0,0-1.841-.464,2.32,2.32,0,1,0,0,4.636,3.845,3.845,0,0,0,1.841-.461V33.71a6.923,6.923,0,0,1-2,.31,4.5,4.5,0,1,1,0-9,6.629,6.629,0,0,1,2,.312Z" transform="translate(-1.981 -7.989)" />
                                <path id="path288" d="M56.889,27.235a2.286,2.286,0,1,0,2.268,2.284,2.245,2.245,0,0,0-2.268-2.284m4.591,2.284a4.59,4.59,0,1,1-4.591-4.5,4.461,4.461,0,0,1,4.591,4.5" transform="translate(-15.984 -7.989)" />
                                <path id="path292" d="M33.128,27.151a7.5,7.5,0,0,0-1.714-.265c-.878,0-1.356.294-1.356.712,0,.529.644.714,1,.828l.605.192A2.614,2.614,0,0,1,33.739,31.1c0,2.183-1.919,2.916-3.6,2.916a13.064,13.064,0,0,1-2.365-.237v-2a8.211,8.211,0,0,0,2.07.323c1.09,0,1.592-.319,1.592-.811,0-.44-.434-.694-.978-.865-.133-.045-.332-.108-.468-.151-1.221-.386-2.239-1.1-2.239-2.541,0-1.625,1.217-2.716,3.237-2.716a9.378,9.378,0,0,1,2.137.276Z" transform="translate(-8.482 -7.988)" />
                                <g id="g383" transform="translate(0 0.03)">
                                  <path id="path296" d="M2.177,11.323a1.088,1.088,0,0,0-2.177,0V13.6a1.088,1.088,0,0,0,2.177,0Z" transform="translate(0 -3.15)" />
                                  <path id="path300" d="M10.786,7.011a1.088,1.088,0,0,0-2.177,0v5.274a1.088,1.088,0,0,0,2.177,0Z" transform="translate(-2.631 -1.832)" />
                                  <path id="path304" d="M19.4,1.115a1.088,1.088,0,0,0-2.176,0V12.65a1.088,1.088,0,0,0,2.176,0Z" transform="translate(-5.263 -0.03)" />
                                  <path id="path308" d="M28,7.011a1.088,1.088,0,0,0-2.177,0v5.274a1.088,1.088,0,0,0,2.177,0Z" transform="translate(-7.894 -1.832)" />
                                  <path id="path312" d="M36.607,11.323a1.085,1.085,0,0,0-2.17,0V13.6a1.085,1.085,0,1,0,2.17,0Z" transform="translate(-10.525 -3.15)" />
                                </g>
                                <g id="use385" transform="translate(23.909 0.03)">
                                  <g id="g383-2" data-name="g383">
                                    <path id="path296-2" data-name="path296" d="M36.608,11.323a1.088,1.088,0,0,0-2.176,0V13.6a1.088,1.088,0,0,0,2.176,0Z" transform="translate(-34.432 -3.15)" />
                                    <path id="path300-2" data-name="path300" d="M45.218,7.011a1.088,1.088,0,0,0-2.177,0v5.274a1.088,1.088,0,0,0,2.177,0Z" transform="translate(-37.063 -1.832)" />
                                    <path id="path304-2" data-name="path304" d="M53.827,1.115a1.088,1.088,0,0,0-2.177,0V12.65a1.088,1.088,0,0,0,2.177,0Z" transform="translate(-39.694 -0.03)" />
                                    <path id="path308-2" data-name="path308" d="M62.436,7.011a1.088,1.088,0,0,0-2.176,0v5.274a1.088,1.088,0,0,0,2.176,0Z" transform="translate(-42.326 -1.832)" />
                                    <path id="path312-2" data-name="path312" d="M71.039,11.323a1.085,1.085,0,0,0-2.17,0V13.6a1.085,1.085,0,1,0,2.17,0Z" transform="translate(-44.957 -3.15)" />
                                  </g>
                                </g>
                                <path id="path332" d="M69.781,36.108v.137h-.387v1.008h-.16V36.245h-.389v-.137Z" transform="translate(-21.041 -11.377)" />
                                <path id="path336" d="M70.31,36.108h.25l.342.938h.006l.331-.938h.245v1.144H71.32v-.938h-.006l-.347.938h-.151l-.338-.938H70.47v.938h-.16Z" transform="translate(-21.488 -11.377)" />
                              </g>
                              <text id="Powered" transform="translate(-0.002 33.03)" font-size="8" font-family="SegoeUI, Segoe UI" letter-spacing="0.02em"><tspan x="9.264" y="9">Powered</tspan></text>
                            </g>
                          </svg>

                        </div>
                      </span>
                    </>)
                    :
                    <>
                      <h3 className='register-heading'>Register to access restricted content</h3>
                      <div className='register-form-container'>
                        <div className='register-container'>
                          <span className='register-formgroup form-group'>
                            <label>First Name*</label>
                            <input type="text"
                              className={`input ${formErrors.firstName ? 'error' : ''}`}
                              value={firstName}
                              onChange={e => { setFirstName(e.target.value); handleRegister() }} />
                            {formErrors.firstName && <span className="error-message">This field is required</span>}
                            <label>Last Name*</label>
                            <input
                              type="text"
                              className={`input ${formErrors.lastName ? 'error' : ''}`}
                              value={lastName}
                              onChange={e => { setLastName(e.target.value); handleRegister() }}
                            />
                            {formErrors.lastName && <span className="error-message">This field is required</span>}

                            <label>Email Address*</label>
                            <input
                              type="email"
                              className={`input ${formErrors.email ? 'error' : ''}`}
                              value={email}
                              onChange={e => { setEmail(e.target.value); handleRegister() }}
                            />
                            {formErrors.email && <span className="error-message">This field is required</span>}

                            <label>Confirm Email Address*</label>
                            <input
                              type="email"
                              className={`input ${formErrors.confirmEmail ? 'error' : ''}`}
                              value={confirmEmail}
                              onChange={e => { setConfirmEmail(e.target.value); handleRegister() }}
                            />
                            {formErrors.confirmEmail && <span className="error-message">This field is required</span>}

                            <label>Telephone Number*</label>
                            <PhoneInput
                              country={'us'}
                              value={telephoneNumber}
                              onChange={e => { setTelephoneNumber(e); handleRegister() }}
                              disableSearchIcon={false}
                            />
                            {formErrors.telephoneNumber && <span className="error-message number">This field is required</span>}
                          </span>

                          <span className='register-formgroup form-group'>
                            <label>Company*</label>
                            <input
                              type="text"
                              className={`input ${formErrors.company ? 'error' : ''}`}
                              value={company}
                              onChange={e => { setCompany(e.target.value); handleRegister() }}
                            />
                            {formErrors.company && <span className="error-message">This field is required</span>}

                            <label>Role*</label>
                            <input
                              type="text"
                              className={`input ${formErrors.role ? 'error' : ''}`}
                              value={role}
                              onChange={e => { setRole(e.target.value); handleRegister() }}
                            />
                            {formErrors.role && <span className="error-message">This field is required</span>}

                            <label>Country*</label>
                            <select
                              className={`country-select ${formErrors.selectedCountry ? 'error' : ''}`}
                              value={selectedCountry}
                              onChange={e => { handleCountryChange(e) }}

                            >
                              <option value=''>Country</option>
                              {countries.map(country => (
                                <option key={country.value} value={country.value}>
                                  {country.label}
                                </option>
                              ))}
                            </select>
                            {/* {formErrors.selectedCountry && <span className="error-message">This field is required</span>}                          <label>Regulatory Body (if known)</label> */}
                            <label>Regulatory Body (if known)</label>
                            <input type="text" className='input' />
                            <label>Regulatory Reference Number (if known)</label>
                            <input type="text" className='input' />

                          </span>
                        </div>
                        <p className='required-fields'>*Required fields</p>
                        <p className='terms'>By clicking Register, you agree to our <a href={pdf}>Terms and Conditions.</a></p>
                        <span className='register-form-handler'>
                          <span className='action-buttons'>
                            <button className="login-button" onClick={showModal}>Cancel</button>
                          </span>
                          <span className='action-buttons hndlereg'>
                            <button className="login-button register-button" onClick={() => { setbtnclickValue(); handleRegister() }}>Submit</button>
                          </span>
                        </span>
                      </div>
                    </>
                }            </>
            )
          }
        </div>
      </div>
      <div className={`overlay ${isModalVisible ? 'before' : contactModal ? 'before' : forgetPassword ? 'before' : ''}`}></div>
      <div className={`login-overlay ${contactModal ?  'show contact-overlay' : ''} ${showContactMessage ? 'contact-message-overlay' : ''}`}>
        <span className={`close ${!showContactMessage ? "contact-close":''}`} onClick={() => setContactModal(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="23.08" height="23.094" viewBox="0 0 23.08 23.094">
            <g id="CLOSE_X" data-name="CLOSE X" transform="translate(-1828.853 -1248.44)">
              <line id="Line_1" data-name="Line 1" x1="0.574" y2="30.538" transform="translate(1850.987 1248.98) rotate(45)" fill="none" stroke="#000" stroke-width="1.5" />
              <line id="Line_2" data-name="Line 2" x1="0.574" y1="30.538" transform="translate(1851.393 1270.588) rotate(135)" fill="none" stroke="#000" stroke-width="1.5" />
            </g>
          </svg>

        </span>
        <div className="contact-content modal-content">
          {
            showContactMessage ? (
              <div className='message-container'>
                <h3 className='login-heading'>THANK YOU</h3>
                <p className='message'>We appreciate you reaching out, and if your application is successful we will contact you within 3-5 business days</p>
                <p className='closewindow' onClick={showContactModal}>CLOSE WINDOW</p>
              </div>
            ) : (

              <>
                <h3 className='login-heading contact-heading'>CONTACT US</h3>
                <div className='contact-us-container'>
                  <div className='location'>
                    <p>United Kingdom</p>
                    <h2>LONDON</h2>
                    <span>
                      <p>BJOU CAPITAL</p>
                      <p>167 Great Portland Street</p>
                      <p>London W1W 5PF</p>
                    </span>
                    <p><a href="mailto:info@bjoucapital.com">E: info@bjoucapital.com</a></p>
                    <p className='directions'>Get Directions <a href='https://maps.app.goo.gl/iR8SpyfV11XiboXc8' target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                      <g id="LOGIN_BUTTON" data-name="LOGIN BUTTON" transform="translate(-450.695 -359)">
                        <g id="ARROW" transform="translate(8.566 0.566)">
                          <g id="Rectangle_22" data-name="Rectangle 22" transform="translate(442.129 358.434)" stroke="#000" stroke-width="1.5">
                            <rect width="28" height="28" rx="14" stroke="none" />
                            <rect x="0.75" y="0.75" width="26.5" height="26.5" rx="13.25" fill="none" />
                          </g>
                          <g id="Group_13" data-name="Group 13" transform="translate(449.387 367.475)">
                            <g id="Group_11" data-name="Group 11" transform="translate(0 4.087)">
                              <path id="Path_25" data-name="Path 25" d="M289.507,928.467a.6.6,0,0,1,.6-.6H302.39a.6.6,0,0,1,0,1.2H290.108A.6.6,0,0,1,289.507,928.467Z" transform="translate(-289.507 -927.866)" fill="#fff" />
                            </g>
                            <g id="Group_12" data-name="Group 12" transform="translate(7.434)">
                              <path id="Path_26" data-name="Path 26" d="M292.6,926.767a.593.593,0,0,1,.142-.387.6.6,0,0,1,.846-.072l4.847,4.088a.619.619,0,0,1,0,.919l-4.847,4.087a.6.6,0,0,1-.774-.921l4.3-3.626-4.3-3.628A.6.6,0,0,1,292.6,926.767Z" transform="translate(-292.599 -926.166)" fill="#fff" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    </a></p>
                  </div>
                  <span className='contact-form-group form-group'>
                    <label>Full Name *</label>
                    <input type="text" className='input' />
                    <label>Email *</label>
                    <input type="email" className='input' />
                    <label>Subject *</label>
                    <input type="text" className='input' />
                    <label>Message *</label>
                    <textarea rows="6" className='input' />
                    <span className='action-buttons'>
                      <button className="login-button" onClick={handleContactMessage}>Send</button>
                    </span>
                  </span>
                </div>
              </>

            )
          }
        </div>
      </div>
      <span className="tablet-cont-2">
        <h2>BJOU CAPITAL </h2>
        <p>167 Great Portland Street
        </p>
        <p> London W1W 5PF</p>
        <p className='copyright'>© 2024 bjou capital ltd. all rights reserved.</p>
      </span>
      {/* forgot modal */}
      <div className={`login-overlay forget-overlay ${forgetPassword ? 'show' : ''}`}>
        <span className="close" onClick={openForget}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 23.08 23.094">
            <g id="CLOSE_X" data-name="CLOSE X" transform="translate(-1828.853 -1248.44)">
              <line id="Line_1" data-name="Line 1" x1="0.574" y2="30.538" transform="translate(1850.987 1248.98) rotate(45)" fill="none" stroke="#000" stroke-width="1.5" />
              <line id="Line_2" data-name="Line 2" x1="0.574" y1="30.538" transform="translate(1851.393 1270.588) rotate(135)" fill="none" stroke="#000" stroke-width="1.5" />
            </g>
          </svg>

        </span>
        <div className="logregister-form forget-container modal-content">
          {
            showMessage ? (
              <div className='message-container'>
                <h3 className='login-heading'>THANK YOU</h3>
                <p className='message'>Password reset email has been sent.</p>
                <p className='closewindow' onClick={openForget}>CLOSE WINDOW</p>
              </div>
            ) : (
              <>
                <h3 className='login-heading forget-heading'>FORGET PASSWORD</h3>
                <span className='login-group form-group forget-group'>
                  <p className='forget-text'>Enter your email address and we will send you link to reset password</p>

                  <input type="email" placeholder="Email *" className='input' onChange={e => setForgetEmail(e.target.value)} value={ForgetEmail} />
                  {ForgetEmailcheck && <span className="error-message email-message-error">This field is required</span>}
                  <span className='action-buttons forget'>
                    <button className="login-button" onClick={handleForgotSubmit}>Reset Password</button>
                    <p className='return-login' onClick={showLogin}>Return to login</p>
                  </span>
                </span>
              </>
            )
          }
        </div>
      </div>
      {/* <FloatingWhatsApp phoneNumber='+971506523353' accountName='BJOU CAPITAL' avatar={logo} statusMessage='Typically replies within few minutes'   /> */}
    </div>
  );
}

export default App;
